
.video-btn {
    color: #1D33E1;
    margin-right: 10px;
    &:hover {
        color: #4557e8;
    }
}
.del-btn {
    color: #FB0338;
    margin-left: 10px;
    &:hover {
        color: #CD062E;
    }
}
.pages-center {
    ::v-deep .el-pager li {
        background-color: transparent !important;
        width: 24px;
        min-width: 24px;
        height: 24px;
        line-height: 26px;
    }
    ::v-deep .el-pager li:not(.disabled).active {
        background-color: #333FD9 !important;
        border-radius: 50%;
        color: #fff;
    }
}
